/*
 * File for CONSTANTS
 */
export const DEV_ENV = "development-";
export const STAGE_ENV = "staging-";
export const LOCAL = "localhost";
export const COMMON_URL_SUFFIX = "www.hapimag.com";

export const BREAKPOINT_XS = "xs";
export const BREAKPOINT_SM = "sm";
export const BREAKPOINT_MD = "md";
export const BREAKPOINT_LG = "lg";
export const BREAKPOINT_XL = "xl";
export const BREAKPOINT_VERTICAL_SM = "(max-height:700px)";

export const square = "\u33A1";
export const euro = "\u20AC";
export const middot = "\u00B7";
export const black_circle = "\u25CF";
export const enDash = "\u2013";
export const emDash = "\u2014";
export const tilde = "\u007E";
export const ellipsis = "\u2026";
export const full_stop = "\u002E";
export const nbsp = "\u00A0";
export const bullet_operator = "\u2219";
export const dot = "\u2022";
export const asterisk = "\u002a";
/**
 * CONTENTFUL CONSTANTS
 */
export const FOOT = "foot";
export const CAR = "car";
export const PUBLIC_TRANSPORT = "public_transport";

export const TABLE_TYPE_BUYER = "buyer";
export const TABLE_TYPE_MY_REQUESTS = "my_requests";
export const TABLE_TYPE_ADMIN = "admin";
export const TABLE_TYPE_SELLER = "seller";
export const OFFER_CREATED_AT = "created_at";
export const OFFER_EXPIRES_AT = "expires_at";
export const OFFER_TOTAL_PRICE = "total";
export const OFFER_SHARES_NUMBER = "number_of_shares";
export const INTEREST = "interest";
export const OFFER_POINTS_NUMBER = "number_of_points";
export const OFFER_PRICE_PER_SHARE = "price_per_share";
export const OFFER_PRICE_PER_POINT = "price_per_point";
export const GUIDE_BUY_SHARE = "buy";
export const GUIDE_SELL_SHARE = "sell";
export const OFFER_STATUS_ACTIVE = "active";
export const OFFER_STATUS_DEACTIVE = "deactive";
export const OFFER_STATUS_EXPIRED = "expired";
export const OFFER_STATUS_RESERVED = "reserved";
export const OFFER_STATUS_FINALISED = "finalised";
export const OFFER_CONTACTED_AT = "contacted_at";

export const OFFER_FILTERS_RANGES_STEP = 100;
export const OFFER_FILTERS_MAX_RANGE_PIXELS = 70;

export const DIRECT_BUY_MAX_SHARES = 10;
export const DIRECT_BUY_POINTS_PER_SHARE = 60;

export const REQUEST_STATUSES = {
    WITHDRAWN: "withdrawn"
};

/**
 * CONTENTFUL IMAGES CONSTANTS
 */
export const DEFAULT_IMAGE_FORMAT = "webp";
export const DEFAULT_IMAGE_QUALITY = 85;

/**
 * CONTENTFUL PAGE NAMES FOR RESORT NOTIFICATIONS
 */

export const RESORT_NOTIFICATIONS_PAGES_RULES = {
    RESORT_DETAIL_PAGE: "RDP",
    CONFRIM_RESERVATION_PAGE: "CRP",
    BOTH: "Both"
};

/**
 * LOCAL STORAGE CONSTANTS
 */
export const SELL_INFO_DIALOG_LAST_TIME = "SELL_INFO_DIALOG_LAST_TIME";
export const CURRENCY = "CURRENCY";
export const NEWSLETTER_DIALOG_SHOWN = "NEWSLETTER_DIALOG_SHOWN";
export const MARKET_PLACE_ONBOARD_POPOVER_SHOWN = "MARKET_PLACE_ONBOARD_POPOVER_SHOWN";
export const USER_REQUESTED_SIGNOUT = "USER_REQUESTED_SIGNOUT";
export const TOP_NOTIFICATION_BANNER_VISIBLE = "TOP_NOTIFICATION_BANNER_VISIBLE";

/**
 * USER SCOPES
 */
export const CUSTOMER_SHARE_MARKETPLACE_USER = "hapimag_customers_share_marketplace_user";
export const EMPLOYEE_CUSTOMER_SUPPORT_VIEWER = "hapimag_employees_customer_support_viewer";
export const EMPLOYEE_CUSTOMER_SUPPORT_EDITOR = "hapimag_employees_customer_support_editor";
export const SHARE_MARKETPLACE_USER = [CUSTOMER_SHARE_MARKETPLACE_USER];
export const CUSTOMER_PROSPECT = "hapimag_customers_sap_crm_prospect";
export const CUSTOMER_MEMBER = "hapimag_customers_sap_crm_member";
export const CUSTOMER_TRIAL = "hapimag_customers_sap_crm_trial";
export const CUSTOMER_EMPLOYEE = "hapimag_customers_sap_crm_employee";
export const CUSTOMER = "hapimag_customers_sap_crm_customer";



/**
 * Date and time constants
 */
export const DATE_DISPLAY_FORMAT = "DD.MM.YYYY";
export const TIME_DISPLAY_FORMAT = "HH:mm";
export const DATETIME_DISPLAY_FORMAT = "DD.MM.YYYY, HH:mm";
export const YEAR_MONTH_DAY_FORMAT = "YYYY-MM-DD";
export const YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS_FORMAT = "YYYY-MM-DD HH:mm:ss";

/**
 * Keycloak constants
 */
export const KEYCLOAK_SESSION_EXPIRATION = 1800; // 30 minutes

/**
 * Resort positioning categories
 */
export const RESORT_POSITIONING_ACTIVITIES = "Activities";
export const RESORT_POSITIONING_HOLIDAY_PREFERENCES = "Holiday preferences";

/**
 * Resort local charges types
 */
export const RESORT_LOCAL_CHARGES_NIGHT = "per_night";
export const RESORT_LOCAL_CHARGES_NIGHT_PERSON = "per_night_and_person";

/**
 * Resort checkin times
 */
export const RESORT_ALL_CHECKIN_TIME = "16:00";
export const RESORT_HOUSEBOAT_CHECKIN_TIME = "14:00";

/**
 * Dialog's button actions enumerator.
 */
export const DIALOG_BUTTON_ACTIONS = {
    success: "SUCCESS",
    cancel: "CANCEL",
};


/**
 * Default currencies for booking purchaces
 */
export const POINTS_EXTRA_PURCHASE_CURRENCY = "EUR";
export const INSURANCE_PURCHASE_CURRENCY = "CHF";
export const TRIAL_POINTS_TO_FIAT_CURRENCY = "CHF";

/*
* TODO: This is a technical debt, we already have this information from Contentful.
*  A small refactoring on UserContext/CurrencyContext might be needed.
* */
export const SUPPORTED_CURRENCIES = ["EUR", "CHF", "GBP"];

/**
 * Default error message pages
 */
export const ERROR_PAGE = {
    404: "404",
    504: "504",
    500: "500",
    UNDER_CONSTRUCTION: "under_construction",
    MAINTENANCE_WINDOW: "maintenance_window"
};

/**
 * Contentful Compose Button Types
 */

export const BUTTON_TYPES = {
    "Primary": {
        variant: "contained",
        color: "primary"
    },
    "Secondary": {
        variant: "contained",
        color: "secondary"
    },
    "Outlined": {
        variant: "outlined",
        color: "primary"
    },
    "Text": {
        variant: "text",
        color: "primary"
    }
};

/**
 * User Personal Details Constants
 */

export const SALUTATIONS = {
    MR: {
        label: "common.label.mr",
        value: "Mr.",
        gender: "m"
    },
    MS: {
        label: "common.label.ms",
        value: "Ms.",
        gender: "f"
    }
};

export const ALL_SALUTATIONS = {
    ...SALUTATIONS,
    ENTERPRISE: {
        label: "common.label.enterprise",
        value: "Enterprise"
    },
    MRS: {
        label: "common.label.mrs",
        value: "Mrs."
    },
    MR_AND_MRS: {
        label: "common.label.mr_and_mrs",
        value: "Mr. and Mrs."
    },
    FAMILY: {
        label: "common.label.family",
        value: "Family"
    },
    COMMUNITY_OF_HEIRS: {
        label: "common.label.community_of_heirs",
        value: "Community of Heirs"
    },
    BARONESS: {
        label: "common.label.baroness",
        value: "Baroness"
    },
    BARON: {
        label: "common.label.baron",
        value: "Baron"
    },
    ESTATE: {
        label: "common.label.estate",
        value: "The Estate"
    },
    COUNTESS: {
        label: "common.label.countess",
        value: "Countess"
    },
    COUNT: {
        label: "common.label.count",
        value: "Count"
    },
};

export const GET_SALUTATION = {
    "Mr.": ALL_SALUTATIONS.MR,
    "Ms.": ALL_SALUTATIONS.MS,
    "Enterprise": ALL_SALUTATIONS.ENTERPRISE,
    "Mrs.": ALL_SALUTATIONS.MRS,
    "Mr. and Mrs.": ALL_SALUTATIONS.MR_AND_MRS,
    "Family": ALL_SALUTATIONS.FAMILY,
    "Community of Heirs": ALL_SALUTATIONS.COMMUNITY_OF_HEIRS,
    "Baroness": ALL_SALUTATIONS.BARONESS,
    "Baron": ALL_SALUTATIONS.BARON,
    "The Estate": ALL_SALUTATIONS.ESTATE,
    "Countess": ALL_SALUTATIONS.COUNTESS,
    "Count": ALL_SALUTATIONS.COUNT,
};

export const EDITABLE_FIELD = {
    TRUE: 1,
    FALSE: 2,
    DISABLED: 3
};

/**
 * Reservation status
 */
export const RESERVATION_STATUS = {
    UPCOMING: "upcoming",
    PAST: "past",
    CANCELLED: "cancelled",
    WAITING_LIST_NEW_BOOKING: "waiting_list_new_booking",
    WAITING_LIST_BOOKING_CHANGE: "waiting_list_booking_change"
};

/**
 * Reservation fields to exclude
 */
export const RESERVATION_EXCLUDE_COSTS = ["costs", "cancellation_info"];

/**
 * Waiting list
 */
export const WAITING_LIST_ENTRIES_MAX = 6;

/**
 * Google Analytics
 */
export const GA_PRICE_PER_POINT = 3;

/**
 * Cookies.TYPE
 */
export const COOKIE_TRIAL_REGISTRATION = {
    name: "isTrialRegistration",
    options: {path: "/", domain: "hapimag.com", sameSite: "lax"}
};

export const COOKIE_INDIRECT_REGISTRATION = {
    name: "isIndirectRegistration",
    options: {path: "/", domain: "hapimag.com", sameSite: "lax"}
};

export const COOKIE_LOGIN_BTN_CLICKED = {
    name: "isLoginBtnClicked",
    options: {path: "/", domain: "hapimag.com", sameSite: "lax"}
};

/**
 * Product types and product statuses
 */

export const PRODUCT_TYPES = {
    SHARE: "share",
    ASHARE: "a-share",
    BSHARE: "b-share",
    F21: "f21",
    LIFETIME: "lifetime",
    CLASSIC: "classic",
    VOUCHER: "voucher",
    EXPLORER: "explorer",
    RESIDENZ_SELECT: "residenz_select",
    RESIDENZ: "residenz",
    POINTS_BASKET: "points_basket",
    FERIDENCE: "feridence",
    HOLIDAY_WORLD_VISA: "holiday_world_visa",
    NEUTRAL: "neutral",
    HOLIDAY_PASS: "holiday_pass",
    PRIVATE_RESIDENCE: "private_residence",
    INSIDER_7_PLUS: "insider_7_plus",
    STARTER: "starter",
    HAPIMAG_7_PLUS: "hapimag_7_plus",
    H7S: "H7S",
    H7M: "H7M",
    H7L: "H7L",
    FLEX_LOW_HIGH: "flex_low_high",
    SELECT_HIGH_4: "select_high_4",
    SELECT_HIGH_5: "select_high_5",
    SELECT: "select",
    SELECT_HIGH: "select_high",
    SELECT_HIGH_SHIFT: "select_high_shift",
    SELECT_LOW: "select_low",
    MEMBERSHIP_HIGH_WITH_SHARE: "membership_high_with_share",
    MEMBERSHIP_HIGH_WITHOUT_SHARE: "membership_high_without_share",
    MEMBERSHIP_LOW: "membership_low",
    MEMBERSHIP: "membership",
    RESIDENCE_POINTS_ON_SUBSCRIPTION: "residence_points_on_subscription",
    POINTS_PRODUCT: "points_product",
    TRIAL_MEMBERSHIP: "trial_membership",
    OTHERS: "others"
};

export const PRODUCT_STATUSES = {
    ACTIVE: "active",
    PAUSED: "paused",
    CANCELLED: "cancelled",
    NON_CONTRIBUTORY: "non-contributory",
    WITHOUT_RIGHT_OF_RESIDENCE: "without_right_of_residence",
    UTILIZED: "utilized",
    CALL_OPTION_CHANGE_A: "call_option_change_a",
    OTHER: "other",
};

/**
  * Sale Offer Points Type
 */
export const SALE_OFFER_POINTS_TYPE = {
    POINTS_PLATFORM: "points_platform",
    SHARE_MARKETPLACE: "share_marketplace",
};


export const ARRIVING_WITH_CAR_SIHOT_IDS = ["13", "23"];
export const GUEST_SALUTATION_VALUES = ["mrs", "mr", "other"];


/**
 * Resort Additional Services
 */
export const ADDITIONAL_SERVICES_PACKAGE_TYPE = {
    OFFER: "A",
    TRANSFER: "T",
    FOOD_BEVERAGE: "Z",
};
export const ADDITIONAL_SERVICES_STATUS = {
    PREVIEW: 0,
    READY_TO_REQUEST: 1,
    REQUESTED: 2,
};
export const ADDITIONAL_SERVICES_LAYOUT = {
    SLIDER: 0,
    GRID: 1
};

export const CHAT_GUURU_APP_ID = "hapimag";

export const POINTS_VALIDITY_RANGE = {
    LESS: 1,
    MORE: 2
};

export const CUSTOMER_DOCUMENTS_SEARCH_TYPES = {
    BY_INVOICE_ID: "invoice_number",
    BY_OPERATION_ID: "process_number"
};

export const POINTS_PLATFORM_ACCESS_TRANSACTION_TYPE = {
    SELL: "sell",
    BUY: "buy",
};

export const DIRECT_DEBIT_EEA_COUNTRIES = ["AT", "BE", "CH", "DE", "FR", "IT", "LI", "LU", "MC", "NL"];
export const DIRECT_DEBIT_STATUS = {
    INACTIVE: 1,
    PENDING: 2,
    ACTIVE: 3
};

export const MY_BANK_ACCOUNTS_ACTIONS = {
    CANCEL_DIRECT_DEBIT_REQUEST: 0,
    DEACTIVATE_DIRECT_DEBIT: 1,
    REMOVE_ACCOUNT: 2
};

// Invoices currency
export const INVOICE_CURRENCY = "CHF";

export const POSTFINANCE_SWIFT_BIC = "POFICHBEXXX";

export const ONETRUST_COOKIES_CATEGORIES = {
    STRICTLY_NECESSARY: "C0001",
    PERFORMANCE: "C0002",
    FUNCTIONAL: "C0003",
    TARGETING: "C0004"
};

// Shares currency
export const SHARES_CURRENCY = "EUR";
export const POINTS_OFFER_CREATE_VIEW_CURRENCY = "EUR";

export const ANNUAL_CHARGE_CURRENCY = "CHF";

export const CONTACT_FORM_FIELD_HASH_OBJ = {
    first_name: "2a034e9d9e2601c21191cca53760eaaf",
    last_name: "7d4553c09a59578c8addc8c617a76ca1",
    email: "0c83f57c786a0b4a39efab23731c7ebc",
    mobile: "532c28d5412dd75bf975fb951c740a30"
};


// Info message ids
export const INFO_MESSAGE_IDS = {
    DOCUMENTS_INVOICES: "documents-invoices",
    POINTS_SHARES: "points-shares",
    MARKETPLACE_BANNER: "marketplace-banner",
};

export const INVOICE_TYPES = {
    ANNUAL_SUBSCRIPTION: "annual_subscription",
    PRODUCT_PURCHASE: "product_purchase",
    PRODUCT_REPURCHASE: "product_repurchase",
    POINTS_PURCHASE: "points_purchase",
    POINTS_SALE: "points_sale",
    PAYMENTS: "payments",
    SERVICES: "services",
    OTHERS: "others"
};

// Help Center
export const HELP_CENTER = {
    section: {
        SHARE_MARKETPLACE: "/topic/0TO09000000CjKPGA0/marketplace-for-shares",
        CONTACT_REQUEST: "/contact-request",
        REQUEST_PRINT_ALL_BOOKING_INFO: "/article/Warum-bieten-wir-keine-Option-Alles-drucken-an",
        SHARE_MARKETPLACE_CONTACT_LIMIT_EXCEEDED: "/article/Warum-ist-mein-Angebot-mit-mehr-als-9-Kaufanfragen-nicht-auf-dem-Marktplatz-sichtbar"
    },
    source: {
        NCE_WEBSITE: "NCE_Website",
        MARKETPLACE_CONTACT: "Marketplace_Contact"
    }
};

// Most common countries
export const MOST_COMMON_COUNTRY_CODES = [
    "AT",
    "FR",
    "DE",
    "IT",
    "NL",
    "CH",
    "TR",
    "GB"
];

// Trial maximum reservations
export const TRIAL_TRIPS_MAX = 3;

// Reservation view statuses
export const RESERVATION_VIEW_STATUSES = {
    COMPLETED: "COMPLETED",
    INCOMPLETE: "INCOMPLETE"
};

// Search results page & Resort detail page
export const SEARCH_RESULT_LIST_SCROLL_SIZE = 5;
export const APARTMENT_RESULT_LIST_SCROLL_SIZE = 5;

export const PAGE_AVAILABILITY_WITHOUT_DATES = "without_dates";
export const PAGE_AVAILABILITY_EMPTY_RESULTS = "empty_results";
export const PAGE_AVAILABILITY_UNAVAILABLE_ALL = "unavailable_all";
export const PAGE_AVAILABILITY_UNAVAILABLE_SOME = "unavailable_some";
export const PAGE_AVAILABILITY_AVAILABLE_ALL = "available_all";

// User role mapping
export const ROLES_MAP = {
    "Prospect": [CUSTOMER_PROSPECT],
    "Trial": [CUSTOMER_TRIAL],
    "Customer": [CUSTOMER],
    "Member": [CUSTOMER_MEMBER, CUSTOMER_EMPLOYEE]
};

export const ENVS_MAP = {
    PRODUCTION: "production",
    STAGING: "staging",
    DEVELOPMENT: "development"
};

// Direct sales max number of shares a customer can buy
export const DIRECT_SALES_BUY_SHARES_LIMIT = 10;
