import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

const initSentry = () => {
    Sentry.init({
        dsn: process.env.SENTRY_DSN || "https://168553bda9534d07b6b829b88880957c@o1355564.ingest.sentry.io/6640215",
        environment: process.env.SENTRY_ENV || "development",
        release: process.env.APP_VERSION,
        tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE || 1.0, // this value is adjusted per environment
        integrations: [new BrowserTracing()],
        beforeSend(event) {
            if (event.exception) {
                Sentry.withScope((scope) => {
                    scope.setTags({
                        version: process.env.APP_VERSION,
                        environment: process.env.SENTRY_ENV
                    });
                });
            }
            return event;
        },
        ignoreErrors: [
            // Random plugins/extensions
            "top.GLOBALS",
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            "originalCreateNotification",
            "canvas.contentDocument",
            "MyApp_RemoveAllHighlights",
            "http://tt.epicplay.com",
            "Can't find variable: ZiteReader",
            "jigsaw is not defined",
            "ComboSearch is not defined",
            "http://loading.retry.widdit.com/",
            "atomicFindClose",
            // Facebook borked
            "fb_xd_fragment",
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            "bmi_SafeAddOnload",
            "EBCallBackMessageReceived",
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            "conduitPage",
            "change_ua",
            "persist_condition",
            "Network Error",
            "Request aborted",
            "timeout of 0ms exceeded",
            "Non-Error exception captured",
            "Non-Error promise rejection captured"
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
    });
};

export default initSentry;
