import React from "react";

const GlobalLoader = () => {
    return (
        <div style={{
            height: "calc(100vh - 48px)",
            width: "100vw",
        }}
        >
            <img
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}
                src={"/images/hapimag-global-boat-loader-min.gif"}
                alt="loading animation"
            />
        </div>
    );
};

export default GlobalLoader;