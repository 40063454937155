import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import * as serviceWorker from "./serviceWorker";
import {STAGE_ENV, DEV_ENV, LOCAL} from "./app/common/constant";
import initSentry from "./app/common/sentry";
import GlobalLoader from "./app/component/widget/skeleton/GlobalLoader";

const App = lazy(() => import("./app/app"));

if(!location.host.includes(LOCAL)) {
    initSentry();
}

ReactDOM.render(
    <div>
        <Helmet>
            {(location.host.includes(STAGE_ENV) || location.host.includes(DEV_ENV)) && (
                <meta
                    name="robots"
                    content="noindex"
                />
            )}
            <title>{"Hapimag"}</title>
            <meta
                name="description"
                content="Hapimag-SPA"
            />
            <meta
                name="seobility"
                content={process.env.SEOBILITY_KEY}
            />
            <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script={process.env.ONETRUST_SCRIPT_ID}
            />
            <script type="text/javascript">
                {"function OptanonWrapper() { }"}
            </script>
            <script src="https://cdn.optimizely.com/js/6302101454716928.js"/>
        </Helmet>

        <Suspense fallback={<GlobalLoader/>}>
            <App/>
        </Suspense>
    </div>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
